/**
 * Pontaのタグ設置
 */
export const addPonta: Function = (reservationId: string) => {
  const script1 = document.createElement('script');
  script1.setAttribute('id', 'ponta-1');
  script1.setAttribute('language', 'javascript');
  script1.setAttribute('src', 'https://sv.pon-tra.jp/ad/js/cv2.js');

  const script2 = document.createElement('script');
  script2.setAttribute('id', 'ponta-2');
  script2.setAttribute('language', 'javascript');
  script2.innerHTML = `<!--
  pontra_cv.cv('admage_pontra_xuid', 'https://sv.pon-tra.jp/ad',
  '_buyer=&_article=82&_price=&_buid=${reservationId}');
  //-->`;

  const noscript = document.createElement('noscript');
  noscript.setAttribute('id', 'ponta-3');
  const img = document.createElement('img');
  img.setAttribute('src', `https://sv.pon-tra.jp/ad/p/cv?_buyer=&_article=82&_price=&_buid=${reservationId}`);
  img.setAttribute('width', '1');
  img.setAttribute('height', '1');
  noscript.appendChild(img);

  const body = document.getElementsByTagName('body')[0];
  body.prepend(script1, script2, noscript);
};

/**
 * Pontaのタグ削除
 */
export const removePonta: Function = () => {
  const ponta1 = document.getElementById('ponta-1');
  if (ponta1) ponta1.remove();
  const ponta2 = document.getElementById('ponta-2');
  if (ponta2) ponta2.remove();
  const ponta3 = document.getElementById('ponta-3');
  if (ponta3) ponta3.remove();
};
