/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import { CarmoTrackerSend, generateEventId, GenerateEventId } from '@/carmo-event-tracker';

type FacebookEventTrackerSend = (options: {
  event_id: string,
  event_name: string,
  pixel_id: string,
  custom_data?: object,
  cv_data?: {
    reservation_id?: string,
    email?: string,
    mobilephone?: string,
    phone?: string,
    gender?: string,
    birthday?: string,
    last_name?: string,
    first_name?: string,
    city?: string,
    prefecture?: string,
    zipcode?: string,
  },
}) => Promise<ReturnType<CarmoTrackerSend>>;

type Window = {
  facebookEventTracker: {
    send: FacebookEventTrackerSend,
    generateEventId: GenerateEventId,
  };
};

/**
 * Facebook広告 ブラウザID をCookieから取得
 */
export const fbp = (): string | undefined => {
  try {
    const fbpPair = document.cookie.split(';')
      .map((pair) => pair.trim().split('='))
      .find((pair) => pair[0] === '_fbp') || [];
    return fbpPair[1] || undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

/**
 * Cookieが有効か確認
 * @returns Cookieが有効かどうか
 */
const isCookieEnabled = () => {
  try {
    // ダミーのCookie作成してみる
    document.cookie = 'cookietest=1';
    const ret = document.cookie.indexOf('cookietest=') !== -1;
    // 確認完了後、ダミーのCookie削除
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return ret;
  } catch (e) {
    return false;
  }
};

/**
 * スリープ関数
 * @param durationInMillisec 待機時間(ms)
 */
const sleep = (durationInMillisec: number) => new Promise(
  (resolve) => setTimeout(resolve, durationInMillisec),
);

/**
 * Facebook広告 クリックID を取得
 * 1. Cookieが有効な場合
 *    _fbc のCookieが生成されるまで待つが、一定時間待っても取得できなければあきらめる
 *    ※ 初流入時はFacebookピクセルが_fbcの値をCookieに格納するまでに時差があるため
 * 2. Cookieが無効な場合
 *    クエリ文字列からfbclidを取得して、fbcの値を生成する。
 *    fbcの値の形式は version.subdomainIndex.creationTime.fbclid となる
 *    https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
 *    ※ creationTimeの値はDate.nowを利用していて、デバイスの時間設定に影響されてしまうが、Facebookピクセル上でも同じ挙動しているので許容
 */
export const fbc = async (): Promise<string | undefined> => {
  // Cookieからfbcの値を取得する関数
  const getFbcFromCookie = () => {
    const pair = document.cookie.split(';')
      .map((p) => p.trim().split('='))
      .find((p) => p[0] === '_fbc') || [];
    return pair[1];
  };

  // クエリ文字列からfbclidの値を取得する関数
  const getFbclidFromQueryString = () => {
    const queryFbclidPair = window.location.search.substring(1).split('&')
      .map((pair) => pair.split('='))
      .find((pair) => pair[0] === 'fbclid') || [];
    return queryFbclidPair[1];
  };

  if (isCookieEnabled()) {
    // Cookieが有効の場合のみ、Cookieから値を取得
    try {
      // Cookieから値取得
      let fbcFromCookie = getFbcFromCookie();
      if (fbcFromCookie) return fbcFromCookie;

      // Cookieにfbcが存在せず、クエリ文字列にfbclidの値が存在するときは、
      // FacebookピクセルがまだfbcをCookieに格納していない状態なので、待機して再取得 (10回までリトライ)
      if (getFbclidFromQueryString()) {
        for (let i = 0; i < 10; i += 1) {
          await sleep(100); // 100ms待機
          fbcFromCookie = getFbcFromCookie(); // 再取得
          if (fbcFromCookie) return fbcFromCookie;
        }
      }
    } catch (e) {
      console.error(e);
    }
  } else {
    // Cookieが無効の場合、URLのクエリ文字列から取得
    try {
      // fbclid取得
      const fbclid = getFbclidFromQueryString();

      if (fbclid) { // fbclidが存在すれば、fbcを生成してLocalStorageに保存し返却
        // fbclidからfbc値を生成。仕様は以下を参照。
        // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc#fbc
        const fbcFromFbclid = `fb.1.${Date.now()}.${fbclid}`;
        window.localStorage.setItem('fbc', fbcFromFbclid);
        return fbcFromFbclid;
      }

      // fbclidも存在しなければ、最後にLocalStorageからfbcを取得
      return window.localStorage.getItem('fbc') || undefined;
    } catch (e) {
      console.error(e);
    }
  }

  return undefined;
};

export const send: FacebookEventTrackerSend = async ({
  event_id,
  event_name,
  pixel_id,
  custom_data = {},
  cv_data = {},
}) => {
  const event = window.carmoTracker.send({
    event_id,
    ...cv_data,
    facebook: {
      pixel_id,
      action_source: 'website',
      event_name,
      user_data: {
        fbc: await fbc(),
        fbp: fbp(),
      },
      custom_data,
    },
  });

  return event;
};

export const initialize = (): void => {
  if (typeof window !== 'undefined') {
    (window as unknown as Window).facebookEventTracker = {
      send,
      generateEventId,
    };
  }
};

(function () {
  initialize();
}());
