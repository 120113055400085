/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// const awsconfig = require('@/aws-exports').default;

export type CarmoTrackerSend = (
  data: {
    event_id?: string,
    [key: string]: unknown,
  }
) => {
  event_id: string,
  [key: string]: unknown,
};

export type GenerateEventId = () => string;

declare global {
  interface Window {
    carmoTracker: {
      generateEventId: GenerateEventId,
      createEvent: (arg: object) => { event_id: string; event_source_url: string; };
      send: CarmoTrackerSend,
      userInfo: {
        user_id: string | undefined
      };
    };
  }
}

/**
 * イベント計測スクリプト初期化
 */
export const initialize = (): void => {
  const user_id = getUserId();
  if (typeof window !== 'undefined') {
    window.carmoTracker = {
      generateEventId,
      createEvent,
      send,
      userInfo: { user_id },
    };
  }
};

/**
 * イベントIDの生成
 * @returns UUID形式のイベントID
 */
export const generateEventId: GenerateEventId = () => uuidv4();

export const createEvent = (arg: object): any => {
  const event_id = generateEventId();
  const event_source_url = window.location.href;
  const { userInfo } = window.carmoTracker;

  const event = {
    event_id, event_source_url, ...userInfo, ...arg,
  };

  return event;
};

export const send: CarmoTrackerSend = (data) => {
  const event_id = (data && data.event_id) || generateEventId();
  const event_source_url = window.location.href;
  const { userInfo } = window.carmoTracker;

  const event = {
    ...userInfo, ...data, event_id, event_source_url,
  };

  // // イベント計測APIエンドポイント情報取得
  // const api = (
  //   awsconfig.aws_cloud_logic_custom as { name: string, endpoint: string }[]
  // ).find((apiConfig) => apiConfig.name === 'CarmoEventTrackerApi');

  // // イベント計測APIエンドポイント情報が発見できない場合、エラーを吐く
  // if (!api) {
  //   throw new Error('イベント計測APIのエンドポイントが見つかりません');
  // }

  // リクエスト送信 (非同期)
  axios.post(`${process.env.GATSBY_CARMO_EVENT_API_BASE_PATH}/api/track-event`, event).catch((e) => console.error(e));

  return event;
};

const getUserId = (): string | undefined => {
  try {
    let user_id = localStorage.carmoTrackerUserId;
    if (!user_id) {
      user_id = uuidv4();
      localStorage.carmoTrackerUserId = user_id;
    }
    return user_id;
  } catch {
    return undefined;
  }
};

(function () {
  initialize();
}());
